const Establishment = class {
    constructor(object) {
        if (object === undefined) {
            this.id = null;
            this.name = null;
            this.code = null;
            this.address = null;
            this.isMain = null;
            this.createdAt = null;
            this.tax = null;
        } else {
            this.id = object.id || null;
            this.name = object.name || null;
            this.code = object.code || null;
            this.address = object.address || null;
            this.isMain = object.isMain || false;
            this.createdAt = object.createdAt || null;
            this.tax = object.tax || null;
        }
    }
}

export default Establishment