const User = class {
    constructor(object) {
        if (object === undefined) {
            this.username = null;
            this.email = null;
            this.firstName = null;
            this.lastName = null;
            this.role = null;
        } else {
            this.username = object.username || null;
            this.email = object.email || null;
            this.firstName = object.firstName || null;
            this.lastName = object.lastName || null;
            this.role = object.role || null;
        }
    }
}

export default User