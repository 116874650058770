import { createRouter, createWebHistory } from 'vue-router';
import store from './store';

const routes = [
    {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import('./App.vue'),
        children: [
            {
                path: '',
                name: 'dashboard-panel',
                component: () => import('./components/Dashboard.vue')
            },
            {
                path: 'general/informacion-basica',
                name: 'informacion-basica',
                component: () => import('./components/Business/BusinessCRUD.vue')
            },
            {
                path: 'general/suscripcion',
                name: 'suscripcion',
                component: () => import('./components/Subscription/Subscriptions.vue')
            },
            {
                path: 'general/configuracion',
                name: 'configuracion',
                component: () => import('./components/Business/Configurations.vue')
            },
            {
                path: 'cliente/crud',
                name: 'customerCRUD',
                component: () => import('./components/Customer/customerCRUD.vue')
            },
            {
                path: 'producto/crud',
                name: 'productCRUD',
                component: () => import('./components/Product/productCRUD.vue')
            },
            {
                path: 'proveedor/crud',
                name: 'providerCRUD',
                component: () => import('./components/Provider/providerCRUD.vue')
            },
            {
                path: 'balance-saldo',
                name: 'Balance',
                component: () => import('./components/Balance/Balance.vue')
            },
            {
                path: 'proforma/crud',
                name: 'proformaCRUD',
                component: () => import('./components/Proforma/proformaCRUD.vue')
            },
            {
                path: 'proforma/form',
                name: 'proformaForm',
                component: () => import('./components/Proforma/proformaForm.vue')
            },
            {
                path: 'venta/crud',
                name: 'saleCRUD',
                component: () => import('./components/Sale/saleCRUD.vue')
            },
            {
                path: 'venta/form',
                name: 'saleForm',
                component: () => import('./components/Sale/saleForm.vue')
            },
            {
                path: 'compra/crud',
                name: 'purchaseCRUD',
                component: () => import('./components/Purchase/purchaseCRUD.vue')
            },
            {
                path: 'compra/form',
                name: 'purchaseForm',
                component: () => import('./components/Purchase/purchaseForm.vue')
            },
            {
                path: 'guia-remision/crud',
                name: 'remisionGuideCRUD',
                component: () => import('./components/RemisionGuide/remisionGuideCRUD.vue')
            },
            {
                path: 'guia-remision/form',
                name: 'remisionGuideForm',
                component: () => import('./components/RemisionGuide/remisionGuideForm.vue')
            },
            {
                path: 'nota-credito/crud',
                name: 'creditNoteCRUD',
                component: () => import('./components/CreditNote/creditNoteCRUD.vue')
            },
            {
                path: 'nota-credito/form',
                name: 'creditNoteForm',
                component: () => import('./components/CreditNote/creditNoteForm.vue')
            },
        ]
    },
    {
        path: '/inicio',
        name: 'landing',
        component: () => import('./pages/LandingDemo.vue')
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('./pages/Login.vue')
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach(function(to, from, next) {
    if (to.fullPath === "/") {
        if (store.getters.isAuthenticated) {
            next({name: "dashboard-panel"});
        } else { next({name: "landing"}); }
    } else { next(); }
});

router.beforeEach(function(to, from, next) {
    if (to.fullPath.startsWith('/login')) {
        if (store.getters.isAuthenticated) {
            next({name: "dashboard-panel"});
        } else { next(); }
    } else { next(); }
});

router.beforeEach(function(to, from, next) {
    if (to.fullPath.startsWith('/dashboard')) {
        if (!store.getters.isAuthenticated) {
            next({name: "login"});
        } else { next(); }
    } else { next(); }
});

export default router;