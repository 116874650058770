import Establishment from "./establishment";

const EmitionPoint = class {
    constructor(object) {
        if (object === undefined) {
            this.id = null;
            this.code = null;
            this.establishment = null;
            this.receiptCount = null;
        } else {
            this.id = object.id || null;
            this.code = object.code || null;
            this.establishment = new Establishment(object.establishment);
            this.receiptCount = object.receiptCount || null;
        }
        
    }
    get secuence() {
        return this.establishment.code + "-" + this.code; 
    }
}

export default EmitionPoint;