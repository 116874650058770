import EmitionPoint from "./emitionPoint";

const EconActivity = class {
    constructor(object) {
        if (object === undefined) {
            this.id = null;
            this.emitionPoint = null;
            this.name = null;
            this.logoUrl = null;
            this.logoFile = null;
        } else {
            this.id = object.id || null;
            this.emitionPoint = new EmitionPoint(object.emitionPoint);
            this.name = object.name || null;
            this.logoUrl = object.logoUrl || null;
            this.logoFile = null;
        }
        
    }

    get logoName() {
        if (this.logoUrl !== undefined && this.logoUrl !== null) {
            let elements = this.logoUrl.split("/");
            return elements[elements.length - 1];
        } else { return "Seleccionar" }
    }
}

export default EconActivity