import User from "../../models/user";
import EconActivity from "../../models/econActivity";
import Business from "../../models/business";

const state = {
    user: User,
    defaultActivity: EconActivity,
    business: Business,
    hasDevices: false,
};

const getters = {
    isAuthenticated: (state) => !!state.user.username || !!state.user.email,
    defaultActivity: (state) => state.defaultActivity,
    business: (state) => state.business,
    getUser: (state) => state.user,
    hasDevices: (state) => state.hasDevices,
};

const actions = {
    async Login({commit}, user) {
        await commit("setUser", user);
    },
    async Logout({ commit }) {
        await commit("logout");
    },
    async setDefaultActivity({commit}, activity) {
        await commit("setDefaultActivity", activity);
    },
    async setBusiness({commit}, business) {
        await commit("setBusiness", business);
    },
    async setDevices({commit}, value) {
        await commit("setDevices", value);
    }
};

const mutations = { 
    setUser(state, user) {
        state.user = new User(user);
    },

    logout(state) {
        state.user = new User(undefined);
    },
    
    setDefaultActivity(state, activity) {
        state.defaultActivity = new EconActivity(activity);
    },

    setBusiness(state, business) {
        state.business = new Business(business);
    },

    setDevices(state, value) {
        state.hasDevices = value;
    }
};

export default {
    state,
    getters,
    actions,
    mutations,
};